<div id="alabia-inner" class="page-layout carded fullwidth inner-scroll">

  <div class="header accent p-24">

    <h1 class="logo-text">Gerenciamento</h1><br>

  </div>
  <div class="menu">
    <a routerLink="/home">Home</a>
    <a routerLink="/cleaning/real-time">Tempo Real</a>
    <a routerLink="/cleaning/maps">Mapas</a>
    <a routerLink="/cleaning/member">Membros</a>
    <a routerLink="/cleaning/report">Analise</a>
  </div>
  <div class="sub-tittle">
    <span>Calendário</span><br>
    <p>
      {{getDayText(currentDate.getDay())}},
      {{currentDate | date :'dd' : '' : 'pt-BR'}} de
      {{currentDate | date :'MMMM' : '' : 'pt-BR'}}
      de
      {{currentDate | date :'YYYY' : '' : 'pt-BR'}}</p>

  </div>
  <div class="filterUnit">
    <mat-form-field>
      <mat-label> Unidade: </mat-label>
      <mat-select style="width: 300px;" (selectionChange)="selectUnit($event.value)">
        <mat-option *ngFor="let unit of unitList " [value]="unit">
          {{ unit.name }}
        </mat-option>
        <mat-option [value]="'all'">
          Todos
        </mat-option>
      </mat-select>
    </mat-form-field>
  </div>
  <div class="body">

    <mat-card class="card-title mat-elevation-z6" style="overflow-y: scroll;">
      <mat-card-header class="title">Planejamento diário </mat-card-header>
      <div class="line"></div>
      <div class="content">
        <mat-card-content>
          <div class="card-task-daily">
            <div class="task-name">
              <div>
                <div class="title-task">
                  <div class="sub-title">Planejado</div>
                  <div class="tasks-today-number" style="font-size: 22px;margin-left: 20px;">
                    {{scheduler[currentDate.getDay()].length}} </div>
                  <div class="internal-card">
                  </div>
                  <div class="task-daily">
                    <div *ngFor="let task of scheduler[currentDate.getDay()]" class="single-task">
                      <div style="display: flex; ">

                        <div style="width: 200px; display: flex;">
                          <mat-icon>
                            pin_drop

                          </mat-icon>
                          <div>
                            {{task.machineTask.name.replace("execute_task_","")}}
                          </div>
                        </div>
                        <div style="width: 200px; display: flex;">
                          <mat-icon>
                            map

                          </mat-icon>
                          <div>
                            {{task.machineTask.map.title.slice(0,20) }}
                          </div>
                        </div>
                        <div style="width: 100px; display: flex;">
                          <mat-icon>
                            schedule
                          </mat-icon>
                          <div>
                            {{task.startTime.toString().slice(0,-3)}}
                          </div>
                        </div>
                      </div>

                    </div>
                  </div>

                </div>
              </div>
              <div class="vertical-line"></div>
              <div>
                <div class="title-task">
                  <div class="sub-title">Pendente</div>
                  <div class="tasks-today-number" style="font-size: 22px;margin-left: 20px; ">
                    {{ pendingTasks ? pendingTasks.length : 0}}
                  </div>
                </div>
                <div class="internal-card">
                  <div class="task-daily">
                    <div *ngFor="let execution of pendingTasks">
                      <div style="display: flex; ">
                        <div style="width: 160px; display: flex;">
                          <div style="width: 200px; display: flex;">
                            <mat-icon>
                              pin_drop

                            </mat-icon>
                            <div>
                              {{execution.name.replace("execute_task_","")}}
                            </div>
                          </div>


                        </div>
                        <div style="width: 200px; display: flex;">
                          <mat-icon>
                            schedule
                          </mat-icon>
                          <div>
                            {{execution.map.title.slice(0,12)}}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>



                </div>
              </div>
            </div>
          </div>

        </mat-card-content>
      </div>

    </mat-card>

    <mat-card class="card-title mat-elevation-z6" style="overflow-y: scroll;">
      <mat-card-header class="title">Tarefas executadas </mat-card-header>
      <div class="line"></div>
      <div class="content">
        <mat-card-content>
          <div class="card-task-daily">
            <div class="task-name">
              <div>
                <div class="title-task">
                  <div class="sub-title">Executado</div>
                  <div class="tasks-today-number" style="font-size: 22px;margin-left: 20px;">
                    {{executedTasks ? executedTasks.length : 0}} </div>
                  <div class="internal-card">
                  </div>
                  <div class="task-daily">
                    <div *ngFor="let task of executedTasks" class="single-task">
                      <div style="display: flex;">
                        <mat-icon>
                          check_circle
                        </mat-icon>
                        <div style="margin-left:0px; width: 200px; ">
                          {{task.name.replace("execute_task_","")}}
                        </div>
                      </div>
                      <div style="justify-content: flex-end;">
                        <!-- {{task.start.toString().slice(0,-3)}} -->
                      </div>
                    </div>
                  </div>

                </div>
              </div>
              <div class="vertical-line"></div>
              <div>
                <div class="title-task">
                  <div class="sub-title">Fora do planejamento</div>
                  <div class="tasks-today-number" style="font-size: 22px;margin-left: 20px; ">
                    {{offplanExecutions ? offplanExecutions.length : 0}}
                  </div>
                </div>
                <div class="internal-card">
                  <div class="task-daily">
                    <div *ngFor="let execution of offplanExecutions">
                      <div style="display:flex">
                        <mat-icon>
                          check_circle
                        </mat-icon>
                        <div>
                          {{execution.name.replace("execute_task_","")}}
                        </div>
                      </div>

                    </div>
                  </div>



                </div>
              </div>
            </div>
          </div>

        </mat-card-content>
      </div>

    </mat-card>
  </div>
  <div class="body">
    <table class="table">
      <tr>
        <th></th>
        <th *ngFor="let task of weekTasks; index as j">
          <div>{{getDayText(j)}}</div>

          <div class="day" *ngIf="currentDate.getDate() != weekDays[j]">{{weekDays[j]}}</div>

          <div class="day" *ngIf="currentDate.getDate() == weekDays[j]" mainTodayColor>{{weekDays[j]}}</div>
          <!-- <div *ngIf="machineExecutionList !=[]">
                      <div *ngFor="let execution of machineExecutionList; index as a">{{execution.start}}</div>
                  </div> -->

        </th>


      </tr>
      <tr *ngFor="let hour of hours; index as i" class="column">
        <td>
          <div class="box-hour">
            {{hour}}:00

          </div>

        </td>
        <td *ngFor="let task of weekTasks; index as j">
          <div *ngFor="let singleTask of task; index as l">
            <div *ngIf="singleTask.startTime.slice(0,-6) == hour" class="task" mainBorderLeftColor>
              <div>
                <mat-icon class="material-symbols-outlined">
                  home

                </mat-icon>
                {{singleTask.machine.location.name}}

              </div>
              <div>
                <mat-icon class="material-symbols-outlined">
                  map

                </mat-icon>
                {{singleTask.machineTask.map.title}}

              </div>


              <div>
                <mat-icon class="material-symbols-outlined">
                  pin_drop

                </mat-icon>
                {{singleTask.machineTask.name.replace('execute_task_','')}}

              </div>
              <div>
                <mat-icon class="material-symbols-outlined">
                  <span class="material-symbols-outlined" style="text-align: center;">
                    robot_2
                  </span>
                </mat-icon>
                {{singleTask.machine.model.model}}

              </div>
              <div class="mini-card-start-end-type">
                <mat-icon class="material-symbols-outlined">
                  schedule
                </mat-icon>
                {{singleTask.startTime.toString().slice(0,-3)}}
              </div>

              <!-- <div *ngFor="let single of singleTask.task" class="taskList">
                              <div *ngIf="machineExecutionList.length > 0">

                                  <mat-icon class="material-symbols-outlined">
                                      {{status(single,weekDays[j])}}

                                  </mat-icon>

                              </div>
                              {{single.name.replace("execute_task_","").slice(0,20)}}
                          </div> -->
            </div>

          </div>

        </td>
      </tr>

    </table>

  </div>


</div>

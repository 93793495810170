import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { WebcamImage } from 'ngx-webcam';
import { Observable } from 'rxjs';
import { v4 as uuidv4 } from 'uuid';
import { Ownable, OwnableService } from '../auth/ownable.service';
import { MachineExecution } from './machine-execution.service';

export interface MachineExecutionAssets extends Ownable {
  id: number;
  execution: MachineExecution;
  path: string;
  type: string;
}

@Injectable({
  providedIn: 'root'
})

export class MachineExecutionAssetsService extends OwnableService<MachineExecutionAssets> {
  protected path: string = `${this.config.endpoint}/hw/assets`;

  public getByExecution(executionId: number): Observable<MachineExecutionAssets> {

    let params = new HttpParams().set('execution', executionId);

    return this.http.get<MachineExecutionAssets>(`${this.path}/getByExecution`, { params: params });
  }

  public uploadManualImg(image: WebcamImage, executionId: number): Observable<MachineExecutionAssets> {
    let base64: string;
    if (image.imageAsBase64.includes(',')) {
      base64 = image.imageAsBase64.split(',')[1];
    } else {
      base64 = image.imageAsBase64;
    }

    try {
      const byteString = atob(base64);
      const arrayBuffer = new ArrayBuffer(byteString.length);
      const intArray = new Uint8Array(arrayBuffer);

      for (let i = 0; i < byteString.length; i++) {
        intArray[i] = byteString.charCodeAt(i);
      }

      const blob = new Blob([intArray], { type: 'image/jpeg' });

      const uniqueFileName = `image_${uuidv4()}.jpg`;

      let formData = new FormData();
      formData.append('machineExecution', executionId.toString());
      formData.append('file', blob, uniqueFileName);

      return this.http.post<MachineExecutionAssets>(`${this.path}/uploadmanualimg`, formData);
    } catch (error) {
      console.error("Erro ao decodificar a string Base64:", error);
      throw new Error("A imagem não pôde ser processada corretamente.");
    }
  }

}

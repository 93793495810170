import { Component, OnInit, ViewChild } from '@angular/core';
import { MatTableModule } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { MachineScheduler, MachineSchedulerService, MachineTask, MachineTaskService, TimedTask } from 'onevoice';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'main-my-manual-tasks',
  templateUrl: './my-manual-tasks.component.html',
  styleUrls: ['./my-manual-tasks.component.scss']
})
export class MyManualTasksComponent implements OnInit {

  public currenteDate: Date = new Date();

  public taskList: MatTableDataSource<MachineTask> = new MatTableDataSource<MachineTask>();
  public scheduler: MachineScheduler[] = [];
  public tasksToday: MatTableDataSource<TimedTask> = new MatTableDataSource<TimedTask>();
  public dataLoaded: boolean = false;
  public fields: string[] = [
    "name",
    "type",
    "start",
    "map",
    "tarefa",
    "actions",
  ];

  constructor(

    public tasks: MachineTaskService,
    public schedulerService: MachineTaskService,
    public machineScheduler: MachineSchedulerService,
    public dialog: MatDialog,

  ) { }

  public ngOnInit(): void {


    this.machineScheduler.listByUser().subscribe(scheduler => {
      console.log(scheduler);
      this.todayTask(scheduler);
      console.log(this.tasksToday.data)
      this.dataLoaded = true;
    })
  }

  public todayTask(scheduler: MachineScheduler[]) {
    scheduler.forEach(schedule => {
      console.log(schedule)
      schedule.timedTaskList.forEach(task => {
        task.repeat.forEach(day => {
          if (day == this.currenteDate.getDay()) {
            this.tasksToday.data.push(task);
          }
        })
      })
    })
  }

  @ViewChild(MatPaginator)
  set paginator(pager: MatPaginator) {
    this.tasksToday.paginator = pager;
  }


}

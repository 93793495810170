import { Component, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { ActivatedRoute, Params } from '@angular/router';
import { WebcamImage } from 'ngx-webcam';
import { ChecklistExecution, ChecklistExecutionService, ChecklistItem, ChecklistItemService, MachineExecution, MachineExecutionAssetsService, MachineExecutionService, MachineTask, MachineTaskService, TriggerType } from 'onevoice';
import { Observable, Subject } from 'rxjs';

@Component({
  selector: 'main-manual',
  templateUrl: './manual.component.html',
  styleUrls: ['./manual.component.scss']
})
export class ManualComponent implements OnInit {
  public task?: MachineTask
  public able: boolean = true
  public comments: string = ''
  public finished: boolean = false
  public haveImage: boolean = false
  public seconds: number = 0;
  public minutes: number = 0;
  public hours: number = 0;

  private trigger: Subject<any> = new Subject();
  public webcamImage: WebcamImage[] = []
  private nextWebcam: Subject<any> = new Subject();
  sysImage = '';

  public form: FormGroup = new FormGroup({

  }, []);
  public typeTask: String[] = ['Terminal', 'Concorrente', 'Revisão']
  public typeExecution: String = 'Concorrente'
  private LAST_TASK: string = "LAST_TASK";
  public checklistItens: ChecklistItem[] = [];
  public executionList: ChecklistExecution[] = [];

  constructor(
    private tasks: MachineTaskService,
    private executions: MachineExecutionService,
    private checklists: ChecklistItemService,
    private checkExecutions: ChecklistExecutionService,
    private assetsService: MachineExecutionAssetsService,
    private _route: ActivatedRoute
  ) {
  }

  get lastExecution(): (MachineExecution | null) {
    let retrieved = localStorage.getItem(this.LAST_TASK);
    if (retrieved === null) {
      return null;
    }
    return JSON.parse(retrieved) as MachineExecution;
  }

  set lastExecution(data: MachineExecution | null) {
    if (data === null) {
      localStorage.removeItem(this.LAST_TASK);
      return;
    }

    localStorage.setItem(this.LAST_TASK, JSON.stringify(data));
  }

  public ngOnInit(): void {


    this._route.params.subscribe((param: Params) => {
      let pk = param["id"] as number;

      if (pk !== undefined) {
        this.tasks.read(pk).subscribe((task) => {
          console.log(task)
          this.task = task;


          this.getCheckLists(task.id)
        });
      }
    });
  }

  public getCheckLists(taskId : number){
    this.checklists.getCheckListByIdTask(taskId).subscribe(checklistItens => {
      this.checklistItens = checklistItens;
    });
  }
  public isChecked(item: ChecklistItem) {
    let list = this.executionList.filter(value => value.item.id == item.id).map((item) => item.checked);
    return list.length > 0 && list.reduce((prev, current, index) => prev || current);
  }

  public addCheck(item: ChecklistItem, value: MatCheckboxChange): void {
    if (this.lastExecution === null) {
      return;
    }
    console.log(value)
    let list = this.executionList.filter(value => value.item.id == item.id);
    if (list.length <= 0) {
      this.checkExecutions.create({
        checked: value.checked,
        execution: this.lastExecution,
        item: item,
      }).subscribe(created => {
          this.executionList.push(created);

      });
    } else {
      for (const execution of list) {
        execution.checked = value.checked;
        this.checkExecutions.update(execution).subscribe(updated => {
          this.executionList = this.executionList.filter(value => value.id !== updated.id);
          this.executionList.push(updated);
        })
      }
    }
  }

  public startTask(): void {
    this.able = false
    if (this.task === undefined) {
      return;
    }
    let task = this.task;

    this.executions.create({
      task: this.task,
      duration: 0.0,
      start: new Date().getTime(),
      data: this.comments,
      completeness: 0.0,
      trigger: TriggerType.MANUAL,
      ...this.form.value,
    }).subscribe(created => {

      this.executionList = [];
      this.lastExecution = created;

      this.checkExecutions.list().subscribe(executionList => {
        this.executionList = executionList.filter(value => value.item.task.id == task.id && value.execution.id == created.id);
      });
    })
  }

  public endTask(): void {
    this.able = true
    this.finished = true
    let execution = this.lastExecution;
    if (execution === null) {
      return;
    }

    if (execution.completeness < 10.0) {
      execution = {
        ...execution,
        completeness: 100.0,
        end: new Date().getTime(),
      };
      execution.duration = execution.end - execution.start;
      execution.data = this.comments;
      this.executionTime(execution.duration)
      this.executions.create(execution).subscribe(execution => {
        console.log(execution)
        this.lastExecution = execution;
        this.uploadImg(execution);
      })
    }

  }
  public uploadImg(execution: MachineExecution) {
    this.webcamImage.forEach(image => {
      console.log(image);
      this.assetsService.uploadManualImg(image ,execution.id).subscribe(data => {
        console.log(data);
      })
    })
  }
  executionTime(time: number) {
    this.seconds = Math.trunc(time / 1000);
    this.minutes = Math.trunc(this.seconds / 60);
    this.hours = Math.trunc(this.minutes / 60)


  }

  public running(): boolean {
    return this.lastExecution != null && this.lastExecution.duration <= 0;
  }

  getTotalArea(): number {
    // let total = this.executionList.map(value => value.item.area).reduce((previous, current) => previous + current, 0);

    let total: number = 0;
    this.executionList.forEach(element => {
      total += element.item.area
    });

    return total
  }
  public getSnapshot(): void {
    this.trigger.next(void 0);
  }
  public captureImg(webcamImage: WebcamImage): void {
    console.log(webcamImage)
    this.webcamImage.push(webcamImage);
    this.sysImage = webcamImage!.imageAsDataUrl;

  }
  public get invokeObservable(): Observable<any> {
    return this.trigger.asObservable();
  }
  public get nextWebcamObservable(): Observable<any> {
    return this.nextWebcam.asObservable();
  }
  public addImage() {
    this.haveImage = true;
  }
  public removeImage() {
    this.haveImage = false;
    this.webcamImage = []
  }
}

<div *ngIf="task !== undefined && finished == false;" class="task-form">
  <div class="task-info">
    <mat-card>
      <mat-card-title>{{ task.name }}</mat-card-title>

      <mat-card-subtitle>{{ task.type }}</mat-card-subtitle>


      <mat-card-subtitle>{{ task.map.title }}</mat-card-subtitle>


      <mat-card-content *ngIf="task.description">
        <!-- <nu-markdown-preview [value]="task.description"></nu-markdown-preview> -->
      </mat-card-content>


      <mat-form-field appearance="fill" class="taskType">
        <mat-label>Tipo de tarefa</mat-label>
        <mat-select [disabled]="able" [(ngModel)]="typeExecution">
          <mat-option *ngFor="let type of typeTask" [value]="type">
            {{type}}
          </mat-option>
        </mat-select>
      </mat-form-field>


      <mat-list>
        <mat-list-item *ngFor="let item of checklistItens">
          <mat-checkbox (change)="addCheck(item, $event)" [disabled]="able" *ngIf="task.type == 'MANUAL'">
            {{ item.content }}
            {{ item.area }} m<sup>2</sup>
          </mat-checkbox>
          <mat-checkbox (change)="addCheck(item, $event)" [disabled]="able" *ngIf="task.type == 'MAINTENANCE'">
            {{ item.machine.nickName }}

          </mat-checkbox>
        </mat-list-item>
      </mat-list>
      <mat-form-field class="example-full-width comments">
        <mat-label>Comentários :</mat-label>
        <textarea [(ngModel)]="comments" matInput placeholder="Ex: Realizei a limpeza parcialmente pois ..."
                  [disabled]="able"></textarea>
      </mat-form-field>




      <button *ngIf="haveImage == false" mat-raised-button color="accent" (click)="addImage()" [disabled]="able">
        Adicionar fotos
      </button>


      <div class="container mt-5" *ngIf="haveImage">
        <div *ngIf="haveImage" class="col-md-12">
          <webcam [trigger]="invokeObservable" (imageCapture)="captureImg($event)"></webcam>
        </div>


        <div class="col-md-12">

          <button mat-raised-button color="accent" (click)="getSnapshot()">
            Tirar fotos
          </button>
          <button *ngIf="haveImage == true" mat-raised-button color="warn" class="btn-removeImage"
            (click)="removeImage()">
            Remove fotos
          </button>
        </div>
        <div class="col-12">
          <div *ngIf="webcamImage.length > 0" id="results">Aquivos selecionados: </div>
          <div *ngFor="let image of webcamImage" class="image-list">
            <img [src]="image.imageAsDataUrl" />
          </div>
        </div>
      </div>
      <mat-card-actions>
        <button mat-raised-button color="accent" *ngIf="!running()" (click)="startTask()">Iniciar</button>
        <button mat-button *ngIf="running()" (click)="endTask()">Finalizar</button>
      </mat-card-actions>

    </mat-card>
  </div>
</div>

<ng-template #spinner>
  <mat-spinner class="alabia-view-spinner"></mat-spinner>
</ng-template>

<div *ngIf="task !== undefined && finished == true" class="finished">
  <mat-card>
    <mat-card-title class="finish-title"> Atividade executada : {{task.name}}</mat-card-title>
    <div style="margin-bottom: 30px; font-size: 20px;"> Tipo de execução: {{typeExecution}}</div>
    <div style="font-size:20px"><span class="material-symbols-outlined">
        task_alt
      </span> Tarefas executadas: </div>
    <div *ngFor="let element of executionList;" class="finish-list">

      <div>
        <div class="material-symbols-outlined">
          check_circle
        </div>
        <div *ngIf="task.type == 'MANUAL'">{{element.item.content}}</div>
        <div *ngIf="task.type == 'MAINTENANCE'">{{element.item.machine.nickName}}</div>
      </div>
    </div>
    <div class="finish-list-time">
      <span class="material-symbols-outlined">
        timer
      </span>
      Tempo gasto :
      <div *ngIf="hours > 0"> {{hours}} h </div>
      <div *ngIf="minutes > 0"> {{minutes}} m </div>
      <div *ngIf="minutes < 1"> {{seconds}} s</div>
    </div>
    <div class="finish-list-area">
      <span class="material-symbols-outlined">
        crop
      </span>
      Área total limpa: {{getTotalArea()}} m<sup>2</sup>
    </div>

    <div class="finish-list-area">
    </div>
    <div *ngIf="comments.length >0" class="finish-list-comment">
      Comentário: {{comments}}

    </div>
    <div *ngIf="webcamImage.length > 0" id="results">Aquivos selecionados: </div>
    <div *ngFor="let image of webcamImage" class="image-list">
      <div>
        <img [src]="image.imageAsDataUrl" />
      </div>
    </div>
    <mat-card-content>
    </mat-card-content>
  </mat-card>
</div>

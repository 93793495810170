<div id="alabia-inner" class="page-layout carded fullwidth inner-scroll">
  <div class="header accent p-24">
    <mat-icon>assessment</mat-icon>
    <h1 class="logo-text">Gerenciamento</h1>
    <br />
  </div>

  <div class="header-range">
    <div class="mat-headline" id="title-dashboard">Tempo real</div>

    <div id="form-field">
      <!-- {{
      today.toLocaleString("default", { day: "numeric" }) +
        " de " +
        today.toLocaleString("default", { month: "long" }) +
        " de " +
        today.toLocaleString("default", { year: "numeric" })
    }} -->
      <mat-form-field appearance="outline" color="accent" style="padding-right: 10px; width: 250px">
        <mat-label>Membro</mat-label>
        <mat-select [(ngModel)]="memberView" (selectionChange)="loadTasksToday()">
          <mat-option value="all">Todos</mat-option>
          <mat-option value="team">Equipe</mat-option>
          <mat-option value="machine">Robôs</mat-option>
        </mat-select>
      </mat-form-field>
      <mat-form-field color="accent" appearance="outline" style="width: 250px">
        <mat-label>Data</mat-label>
        <input matInput [matDatepicker]="picker1" [formControl]="date" (dateChange)="interval()" />
        <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
        <mat-datepicker #picker1></mat-datepicker>
      </mat-form-field>
    </div>
  </div>

  <!-- Cards -->
  <div class="cards ml-12 mr-12">
    <!-- <div class="functionality mat-elevation-z2">
      <div class="mat-title" style="margin-bottom: 5px">Meta diária</div>
      <mat-card-content
        style="align-self: center; top: -60px; margin-bottom: 0px"
      >
        <mat-progress-spinner
          diameter="90"
          color="accent"
          mode="determinate"
          value="70"
          style="margin-bottom: -30px"
        >
        </mat-progress-spinner>
        <span
          style="
            text-align: center;
            top: -38px;
            font-size: 28px;
            position: relative;
            left: 20px;
          "
          >70%</span
        >
      </mat-card-content>
    </div> -->

    <div class="functionality mat-elevation-z2">
      <div class="mat-title">Tarefas</div>
      <div class="description-layout">
        <div class="desc-column">
          <div class="mat-headline" style="color: #9a75fc">
            {{ tasksManual }}/{{ dataSourceTask.data.length }}
          </div>
          <div>Manuais</div>
        </div>
        <div class="divider"></div>
        <div class="desc-column">
          <div class="mat-headline" style="color: #9a75fc">
            {{ dataSourceTask.data.length - tasksManual }}/{{
            dataSourceTask.data.length
            }}
          </div>
          <div>Automáticas</div>
        </div>
      </div>
    </div>

    <div class="functionality mat-elevation-z2">
      <div class="mat-title">Mapas de limpeza</div>
      <div class="description-layout">
        <div class="desc-column">
          <div class="mat-headline" style="color: #9a75fc">
            {{ dataSourceMaps.data.length }}
          </div>
          <div>Mapas com tarefas concluídas</div>
        </div>
      </div>
    </div>

    <div class="functionality mat-elevation-z2">
      <div class="mat-title">Tempo de limpeza hoje</div>
      <div class="description-layout">
        <div class="desc-column">
          <div class="mat-headline"
            style="color: #9a75fc; display: flex; align-items: center; justify-content: center;">

            <div>{{timeByExecution}}</div>
            <!-- <div *ngIf="durationLength / 3600>=1">{{ (durationLength/3600).toFixed(0) + " h" }}</div>
            <div *ngIf="durationLength / 3600<1"> 0 h</div>

            <div *ngIf="durationLength % 3600>=1 ">{{ ((durationLength%3600)/60).toFixed(0) + " min"}}</div> -->
          </div>
          <div>Horas</div>
        </div>
      </div>
    </div>

    <!-- <div class="functionality mat-elevation-z2">
      <div class="mat-title">Tempo de limpeza Total </div>
      <div class="description-layout">
        <div class="desc-column">
          <div class="mat-headline"
            style="color: #9a75fc; display: flex; align-items: center; justify-content: center;">

            <div>{{totalTimeExecutionAllTime}}</div>

          </div>
          <div>Horas</div>
        </div>
      </div>
    </div> -->

    <div class="functionality mat-elevation-z2">
      <div class="mat-title">Membros</div>
      <div class="description-layout">
        <div class="desc-column">
          <div class="mat-headline" style="color: #9a75fc">
            {{ members.length }}
          </div>
          <div>Presentes</div>
        </div>
        <!-- <div class="divider"></div> -->
        <!-- <div class="desc-column">
          <mat-progress-spinner
            color="accent"
            mode="determinate"
            value="83"
            diameter="80"
            style="margin: 0 auto"
          >
          </mat-progress-spinner>
          <span
            class="mat-headline"
            style="
              text-align: center;
              top: -60px;
              font-size: 24px;
              position: relative;
            "
            >83%</span
          >
        </div> -->
      </div>
    </div>
  </div>

  <!-- tabs -->
  <mat-tab-group class="mat-elevation-z2 ml-16 mr-16 mt-12" color="accent" (selectedTabChange)="changeTab($event)">
    <mat-tab label="Tarefas">
      <mat-progress-bar *ngIf="isLoading2" mode="indeterminate" color="accent"></mat-progress-bar>
      <table mat-table [dataSource]="dataSourceTask" class="mat-elevation-z8" matSort matSortActive="start"
        matSortDisableClear matSortDirection="desc" #table>
        <!-- Id Column -->
        <!-- <ng-container matColumnDef="id">
        <th mat-header-cell *matHeaderCellDef mat-sort-header disableClear>
          No.
        </th>
        <td mat-cell *matCellDef="let element">{{ element.id }}</td>
      </ng-container> -->

        <!-- Name Column -->
        <ng-container matColumnDef="name">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Nome</th>
          <td mat-cell *matCellDef="let element">{{ element.task.name }}</td>
        </ng-container>

        <!-- Map Column -->
        <ng-container matColumnDef="map">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Mapa</th>
          <td mat-cell *matCellDef="let element">
            {{
            element.data.mapName
            ? element.data.mapName
            : element.task.map.title
            }}
          </td>
        </ng-container>

        <!-- Machine Column -->
        <ng-container matColumnDef="machine">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Robô</th>
          <td mat-cell *matCellDef="let element">
            {{
            element.task == "AUTO" ? element.machine.name : element.task.type
            }}
          </td>
        </ng-container>

        <!-- Status Column -->
        <ng-container matColumnDef="status">
          <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="staus">
            Status
          </th>
          <td mat-cell *matCellDef="let element" [ngStyle]="{
              color: element.end ? '#31d159' : '#ff9500'
            }">
            {{ getStatus(element)}}
          </td>
        </ng-container>

        <!-- Start Column -->
        <ng-container matColumnDef="start">
          <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Início">
            Início
          </th>
          <td mat-cell *matCellDef="let element">
            {{ element.start | date: "medium" }}
          </td>
        </ng-container>
        <!-- (row.duration / 60).toFixed(2) | number -->

        <!-- Duration Column -->
        <ng-container matColumnDef="duration">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Duração</th>
          <td mat-cell *matCellDef="let element">
            {{ (formatTimeExecution(element)) }}

          </td>
        </ng-container>

        <!-- Operator Column -->
        <ng-container matColumnDef="operator">
          <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="membro">
            Membro
          </th>
          <td mat-cell *matCellDef="let element">
            {{
            element.operator.charAt(0).toUpperCase() +
            element.operator.slice(1)
            }}
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumnsTask"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumnsTask"></tr>
      </table>
      <mat-paginator [length]="100" [pageSize]="10" [showFirstLastButtons]="true" [pageSizeOptions]="[5, 10, 25, 100]"
        aria-label="Select page" #taskPaginator>
      </mat-paginator>
    </mat-tab>

    <mat-tab label="Mapas">
      <mat-card style="min-width: 900px">
        <div class="tab-map-layout">
          <div class="tab-map-layout-col1">
            <div class="col1-title mat-subheading-2">Mapas Planejados</div>
            <div class="col1-grid">
              <div *ngFor="let map of obsMaps | async; index as i" class="map-card mat-elevation-z2"
                (click)="changeMap(map)" id="{{ map == selectedMap ? 'selectedMap' : null }}">
                <div class="mat-subheading-2 mat-tipography" style="margin-bottom: 0px">
                  <button mat-mini-fab color="#fff">
                    <mat-icon>place</mat-icon>
                  </button>
                  <span style="padding-left: 15px">Mapa {{ map.id }}</span>
                  <h3 style="margin-bottom: 0px">
                    Nome <br />
                    {{ map.title }}
                  </h3>
                </div>
                <!-- <div class="map-card-status mat-elevation-z2">em processo</div> -->
              </div>

              <!-- <div
                class="map-card mat-elevation-z2"
                (click)="changeMap('mapa2', 2)"
                id="{{ selectedMap == 'mapa2' ? selectedMap : null }}"
              >
                <div class="mat-subheading-2" style="margin-bottom: 0px">
                  <button mat-mini-fab color="#fff">
                    <mat-icon>schedule</mat-icon>
                  </button>
                  <span style="padding-left: 15px">Mapa 2</span>
                  <h3 style="margin-bottom: 5px">
                    2º Andar <br />
                    Ed. Sede
                  </h3>
                </div>
                <div class="map-card-status mat-elevation-z2">em processo</div>
              </div> -->

              <!-- <div
                class="map-card mat-elevation-z2"
                style="margin-bottom: 0px"
                (click)="changeMap('mapa3', 3)"
                id="{{ selectedMap == 'mapa3' ? selectedMap : null }}"
              >
                <div class="mat-subheading-2">
                  <button mat-mini-fab color="#fff">
                    <mat-icon>schedule</mat-icon>
                  </button>
                  <span style="padding-left: 15px">Mapa 3</span>
                  <h3 style="margin-bottom: 0px">
                    3º Andar <br />
                    Ed. Sede
                  </h3>
                </div>
                <div class="map-card-status mat-elevation-z2">em processo</div>
              </div>

              <div
                class="map-card mat-elevation-z2"
                style="margin-bottom: 0px"
                (click)="changeMap('mapa4', 4)"
                id="{{ selectedMap == 'mapa4' ? selectedMap : null }}"
              >
                <div class="mat-subheading-2">
                  <button mat-mini-fab color="#fff">
                    <mat-icon>schedule</mat-icon>
                  </button>
                  <span style="padding-left: 15px">Mapa 4</span>
                  <h3 style="margin-bottom: 0px">
                    4º Andar <br />
                    Ed. Sede
                  </h3>
                </div>
                <div class="map-card-status mat-elevation-z2">em processo</div>
              </div>

              <div
                class="map-card mat-elevation-z2"
                style="margin-bottom: 0px"
                (click)="changeMap('mapa5', 5)"
                id="{{ selectedMap == 'mapa5' ? selectedMap : null }}"
              >
                <div class="mat-subheading-2">
                  <button mat-mini-fab color="#fff">
                    <mat-icon>schedule</mat-icon>
                  </button>
                  <span style="padding-left: 15px">Mapa 5</span>
                  <h3 style="margin-bottom: 0px">
                    5º Andar <br />
                    Ed. Sede
                  </h3>
                </div>
                <div class="map-card-status mat-elevation-z2">em processo</div>
              </div>

              <div
                class="map-card mat-elevation-z2"
                style="margin-bottom: 0px"
                (click)="changeMap('mapa6', 6)"
                id="{{ selectedMap == 'mapa6' ? selectedMap : null }}"
              >
                <div class="mat-subheading-2">
                  <button mat-mini-fab color="#fff">
                    <mat-icon>schedule</mat-icon>
                  </button>
                  <span style="padding-left: 15px">Mapa 6</span>
                  <h3 style="margin-bottom: 0px">
                    6º Andar <br />
                    Ed. Sede
                  </h3>
                </div>
                <div class="map-card-status mat-elevation-z2">em processo</div>
              </div> -->
            </div>
            <mat-paginator [pageSize]="6" [showFirstLastButtons]="true" [pageSizeOptions]="[6, 12, 24, 48]"
              aria-label="Select page" [ngStyle]="{
                display: dataSourceMaps.data.length < 6 ? 'none' : ''
              }">
            </mat-paginator>
          </div>

          <div class="tab-map-layout-col3">
            <div class="mat-subheading-2">Mapa {{ selectedMap?.id }} - Lista de Tarefas</div>
            <div class="col3-expanded">
              <mat-accordion>
                <mat-expansion-panel disabled>
                  <mat-expansion-panel-header>
                    <mat-panel-title style="
                        color: #0000008a;
                        width: 2vw;
                        padding-right: 10px;
                        word-wrap: break-word;
                      ">
                      Nº
                    </mat-panel-title>
                    <mat-panel-description style="
                        color: #0000008a;
                        width: 18.5vw;
                        padding-right: 10px;
                        word-wrap: break-word;
                        min-width: 190px;
                      ">
                      Nome
                    </mat-panel-description>
                    <mat-panel-description style="color: #0000008a; width: 6vw; padding-right: 10px">
                      Início
                    </mat-panel-description>
                    <mat-panel-description style="
                        color: #0000008a;
                        width: 7vw;
                        padding-right: 7px;
                        min-width: 130px;
                      ">
                      Tipo de tarefa
                    </mat-panel-description>
                    <mat-panel-description style="color: #0000008a; width: 7vw; padding-right: 10px">
                      Membro
                    </mat-panel-description>
                  </mat-expansion-panel-header>
                </mat-expansion-panel>
                <mat-expansion-panel *ngFor="let item of obsTask | async" (click)="getAsset(item)">
                  <mat-expansion-panel-header (click)="getCheckListItem(item)">
                    <mat-panel-title id="panel-title-num" style="width: 0.5vw; padding-right: 10px">
                      {{ dataSourceMapSelected.data.indexOf(item) + 1 }}
                    </mat-panel-title>
                    <mat-panel-description style="
                        color: #000000de;
                        width: 14vw;
                        padding-right: 10px;
                        word-wrap: break-word;
                        min-width: 80px;
                      ">
                      {{ item.task.name.replace('execute_task_','').replaceAll("-"," ").slice(0, 20)}}
                    </mat-panel-description>

                    <mat-panel-description style="
                        color: #000000de;
                        width: 2.5vw;
                        padding-right: 10px;
                      ">
                      {{ item.start | date: "shortTime" }}
                    </mat-panel-description>

                    <mat-panel-description style="
                        color: #000000de;
                        width: 2.5vw;
                        padding-right: 10px;
                        min-width: 150px;
                      ">
                      {{ item.trigger == trigger ? "Manual" : "Automático" }}
                    </mat-panel-description>
                    <mat-panel-description style="
                        color: #000000de;
                        width: 2.5vw;
                        padding-right: 10px;
                      ">
                      {{ item.operator }}
                    </mat-panel-description>
                  </mat-expansion-panel-header>
                  <ng-template matExpansionPanelContent>
                    <h3 *ngIf="item.machine">
                      Detalhes do robô:
                      <b>
                        {{ item.machine.name }}
                      </b>
                    </h3>
                    <div class="col3-expanded-content">
                      <div style="display: flex;">
                        <div class="col3-expanded-content-col1">
                          <table *ngIf="item.trigger != trigger">
                            <tr>
                              <td>Modelo Robô</td>
                              <td>{{ item.machine.model.model }}</td>
                            </tr>
                            <tr>
                              <td>Tipo de limpeza</td>
                              <td>
                                {{ translate(item.data.cleaningMode) }}
                              </td>
                            </tr>
                            <tr>
                              <td>Área limpa</td>
                              <td>
                                {{ item.data.coverageArea.toFixed(2) | number }}m²
                              </td>
                            </tr>
                            <tr>
                              <td>Área de cobertura</td>
                              <td>
                                {{ item.data.totalArea.toFixed(2) | number }}m²
                              </td>
                            </tr>
                            <tr>
                              <td>Taxa de sucesso</td>
                              <td>
                                {{
                                (
                                (item.data.coverageArea * 100) /
                                item.data.totalArea
                                ).toFixed(2) | number
                                }}%
                              </td>
                            </tr>
                            <tr>
                              <td>Dia</td>
                              <td>{{ item.start | date: "dd-MM-yyyy" }}</td>
                            </tr>
                          </table>

                          <table *ngIf="item.trigger == trigger">
                            <tr>
                              <td>Funcionário(a)</td>
                              <td>{{ item.operator }}</td>
                            </tr>
                            <tr>
                              <td>Mapa</td>
                              <td>{{ item.task.map.title }}</td>
                            </tr>
                            <tr *ngIf="item.trigger != 'MANUAL'">
                              <td>Taxa de conclusão</td>
                              <td>
                                {{ item.completeness.toFixed(2) | number }}%
                              </td>
                            </tr>
                            <tr>
                              <td>Tarefa</td>
                              <td>
                                {{ item.task.name}}%
                              </td>
                            </tr>
                          </table>
                        </div>

                        <div class="col3-expanded-content-col2">
                          <table *ngIf="item.trigger != trigger">

                            <tr>
                              <td>Início</td>
                              {{ item.start | date: "mediumTime" }}
                            </tr>
                            <tr>
                              <td>Fim</td>
                              <td>{{ item.end | date: "mediumTime" }}</td>
                            </tr>
                            <!-- <tr> -->
                            <!-- <td>Duração</td> -->
                            <!-- <td> -->
                            <!-- {{ (item.data.duration / 60).toFixed(0) | number }} min  {{ (item.data.duration % 60) | number }} seg -->
                            <!-- {{(item.end - item.start | date: "mediumTime")?.slice(3)}} -->
                            <!-- </td> -->
                            <!-- </tr> -->
                            <tr>
                              <td>Qtd. de bateria</td>
                              <td>
                                {{ (item.data.beginBatteryLevel - item.data.endBatteryLevel) }}%

                              </td>
                            </tr>
                            <tr>
                              <td *ngIf="translate(item.data.cleaningMode) != 'Varrer'">Qtd. de água</td>
                              <td *ngIf="translate(item.data.cleaningMode) != 'Varrer'">
                                {{ item.data.waterUsage.toFixed(6) | number }}L
                              </td>
                            </tr>
                            <tr>
                              <td *ngIf="translate(item.data.cleaningMode) != 'Varrer'">Qtd. de produto</td>
                              <td *ngIf="translate(item.data.cleaningMode) != 'Varrer'">
                                {{ item.data.waterUsage.toFixed(6)/90 | number }}L
                              </td>
                            </tr>
                          </table>
                          <table *ngIf="item.trigger == trigger">
                            <tr>
                              <td>Início</td>
                              <td>{{ item.start | date: "HH:mm" }}</td>
                            </tr>
                            <tr>
                              <td>Fim</td>
                              <td>
                                {{ item.end ? (item.end | date: "HH:mm") : "" }}
                              </td>
                            </tr>
                            <tr>
                              <td>Duração</td>
                              <td>
                                {{
                                  formatTimeExecution(item)
                                }}
                              </td>
                            </tr>

                          </table>
                        </div>
                      </div>
                      <div *ngIf="comments(item)">
                        <div>
                          <h3>Comentários:</h3>
                          <div style="color: purple">{{item.data}}</div>
                        </div>
                      </div>
                      <div *ngIf="item.trigger == trigger">
                        <H3>Itens:</H3>
                        <div *ngFor="let checklist of checkListExec" style="display:flex;">
                          <div style="margin:10px; color:purple">
                            <mat-icon>{{checklist.item.content ? 'checked_circle':'cancel'}}</mat-icon>
                          </div>
                          <div style="margin:10px">
                            {{checklist.item.content}}
                          </div>

                        </div>
                      </div>
                      <div class="img-map-execution" style="  overflow-x: auto;">
                        <div *ngIf="loadimg">
                          <mat-spinner></mat-spinner>
                        </div>

                        <div *ngFor="let url of foundAsset">
                          <button mat-button (click)="openSnackBar(url,'close')">
                            <img src="{{url}}" alt="" style="padding: 15px;max-width: 300px; max-height: 450px;">
                          </button>
                        </div>
                      </div>
                    </div>

                    <!-- <mat-divider></mat-divider> -->
                    <!-- <mat-card-actions align="end">
                      <button mat-raised-button color="#fff">Acessar</button>
                    </mat-card-actions> -->
                  </ng-template>
                </mat-expansion-panel>
                <mat-paginator [length]="100" [pageSize]="10" [showFirstLastButtons]="true"
                  [pageSizeOptions]="[5, 10, 25, 100]" aria-label="Select page">
                </mat-paginator>
              </mat-accordion>
            </div>
          </div>
        </div>
      </mat-card>
    </mat-tab>

    <mat-tab label="Membros" disabled="true">

    </mat-tab>

    <mat-tab label="Histórico">
      <mat-progress-bar *ngIf="isLoading" mode="indeterminate" color="accent"></mat-progress-bar>
      <table mat-table [dataSource]="dataSourceHistory" class="mat-elevation-z8" matSort matSortActive="id"
        matSortDisableClear matSortDirection="desc" #tableHistory>
        <!-- Id Column -->
        <ng-container matColumnDef="id">
          <th mat-header-cell *matHeaderCellDef mat-sort-header disableClear>
            No.
          </th>
          <td mat-cell *matCellDef="let element">{{ element.id }}</td>
        </ng-container>

        <!-- Name Column -->
        <ng-container matColumnDef="name">
          <th mat-header-cell *matHeaderCellDef>Nome</th>
          <td mat-cell *matCellDef="let element">{{ element.task.name }}</td>
        </ng-container>

        <!-- Mapa Column -->
        <ng-container matColumnDef="map">
          <th mat-header-cell *matHeaderCellDef>Mapa</th>
          <td mat-cell *matCellDef="let element">
            {{ element.task.map.title }}
          </td>
        </ng-container>

        <!-- Status Column -->
        <ng-container matColumnDef="status">
          <th mat-header-cell *matHeaderCellDef>Status</th>
          <td mat-cell *matCellDef="let element" [ngStyle]="{
              color: element.duration ? '#31d159' : '#ff9500'
            }">
            {{ element.duration ? "Teste" : "Em progresso" }}
          </td>
        </ng-container>

        <!-- Start Column -->
        <ng-container matColumnDef="start">
          <th mat-header-cell *matHeaderCellDef>Início</th>
          <td mat-cell *matCellDef="let element">
            {{ element.start | date: "medium" }}
          </td>
        </ng-container>

        <!-- Duration Column -->
        <ng-container matColumnDef="duration">
          <th mat-header-cell *matHeaderCellDef>Duração</th>
          <td mat-cell *matCellDef="let element">
            {{
            element.duration
            ? ((element.duration / 60).toFixed(2) | number) + " min"
            : ""
            }}
          </td>
        </ng-container>

        <!-- Operator Column -->
        <ng-container matColumnDef="operator">
          <th mat-header-cell *matHeaderCellDef>Membro</th>
          <td mat-cell *matCellDef="let element">{{ element.operator }}</td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
      </table>

      <!-- <table
        mat-table
        [dataSource]="dataSourceHistory"
        class="mat-elevation-z8"
        matSort
        matSortActive="number"
        matSortDisableClear
        matSortDirection="desc"
      >
        <ng-container matColumnDef="number">
          <th
            mat-header-cell
            *matHeaderCellDef
            mat-sort-header
            sortActionDescription="ordem"
          >
            Nº
          </th>
          <td mat-cell *matCellDef="let item">
            {{ item.id }}
          </td>
        </ng-container>

        <ng-container matColumnDef="name">
          <th mat-header-cell *matHeaderCellDef>Nome</th>
          <td mat-cell *matCellDef="let item">{{ item.task.name }}</td>
        </ng-container>

        <ng-container matColumnDef="map">
          <th mat-header-cell *matHeaderCellDef>Mapa</th>
          <td mat-cell *matCellDef="let item">{{ item.task.map.title }}</td>
        </ng-container>

        <ng-container matColumnDef="status">
          <th mat-header-cell *matHeaderCellDef>Status</th>
          <td
            mat-cell
            *matCellDef="let item"
            [ngStyle]="{
              color: item.duration ? '#31d159' : '#ff9500'
            }"
          >
            {{ item.duration ? "Concluído" : "Em progresso" }}
          </td>
        </ng-container>

        <ng-container matColumnDef="start">
          <th mat-header-cell *matHeaderCellDef>Inínio</th>
          <td mat-cell *matCellDef="let item">
            {{ item.start | date: "medium" }}
          </td>
        </ng-container>

        <ng-container matColumnDef="duration">
          <th mat-header-cell *matHeaderCellDef>Duração</th>
          <td mat-cell *matCellDef="let item">
            {{ (item.duration / 60).toFixed(2) | number }}min
          </td>
        </ng-container>

        <ng-container matColumnDef="operator">
          <th mat-header-cell *matHeaderCellDef>Membro</th>
          <td mat-cell *matCellDef="let item">{{ item.operator }}</td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
      </table> -->
      <mat-paginator [length]="100" [pageSize]="10" [showFirstLastButtons]="true" [pageSizeOptions]="[5, 10, 25, 100]"
        aria-label="Select page" #historyPaginator>
      </mat-paginator>
    </mat-tab>
  </mat-tab-group>

  <div style="height: 50px"></div>
</div>

import { HttpParams } from '@angular/common/http';
import {
  AfterViewInit,
  Component,
  ElementRef,
  OnInit,
  QueryList,
  Renderer2,
  ViewChild,
  ViewChildren,
} from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatPaginator } from '@angular/material/paginator';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import {
  AuthenticationService,
  ChecklistExecution,
  ChecklistExecutionService,
  ChecklistItem,
  ChecklistItemService,
  MachineExecution,
  MachineExecutionAssets,
  MachineExecutionAssetsService,
  MachineExecutionService,
  MachineMap,
  MachineTask,
  TriggerType,
  User,
  UserService
} from 'onevoice';
import { Observable } from 'rxjs';
import { SnackbarImageComponent } from './snackbar/snackbar-image.component';
import { exec } from 'child_process';
import { min } from 'lodash';


function ByDate(p1: MachineExecution, p2: MachineExecution) {
  return p2.start - p1.start;
}

@Component({
  selector: 'cleaning-detail',
  templateUrl: './cleaning-detail.component.html',
  styleUrls: ['./cleaning-detail.component.scss'],
})
export class CleaningDetailComponent implements OnInit, AfterViewInit {
  public today: Date = new Date();
  public displayedColumns: string[] = [
    'id',
    'name',
    'map',
    'status',
    'start',
    'duration',
    'operator',
  ];
  public displayedColumnsTask: string[] = [
    'name',
    'map',
    'machine',
    'status',
    'start',
    'duration',
    'operator',
  ];
  public displayedColumnsMember: string[] = [
    'num',
    'nome',
    'status',
    'entrada',
    'saida',
    'periodo',
    'carrinho',
    'actions',
  ];
  // public dataSourceMaps = new MatTableDataSource<Map>([]);
  public memberView = 'all';
  public selectedMap?: MachineMap;
  public idMap: number = 4;
  public dateView: Date = new Date();
  // public dataSourceMaps = MAPS;

  // dados reais
  // private params: HttpParams = new HttpParams();
  public date = new FormControl(new Date());
  public dataSourceHistory = new MatTableDataSource<MachineExecution>([]);
  public dataSourceTask = new MatTableDataSource<MachineExecution>([]);
  public dataSourceUsers = new MatTableDataSource<User>([]);
  public dateParam: Date = new Date();
  public dataSourceMaps: MatTableDataSource<MachineMap> =
    new MatTableDataSource<MachineMap>();
  public dataSourceMapSelected: MatTableDataSource<MachineExecution> =
    new MatTableDataSource<MachineExecution>();
  public dataSourceTaskList: MatTableDataSource<MachineTask> =
    new MatTableDataSource<MachineTask>();
  public obsMaps?: Observable<any>;
  public obsTask?: Observable<any>;
  public trigger: TriggerType = TriggerType.MANUAL;
  public isLoading: boolean = true;
  public isLoading2: boolean = true;
  public tasksManual: number = 0;
  public urlMapImg: string = '';
  public durationLength: number = 0;
  public cleanType = new Map();
  public members: string[] = [];
  public foundAsset: string[] = [];
  public loadimg: boolean = true;
  public checkListExec: ChecklistExecution[] = [];
  public checkListNotExecuted: ChecklistItem[] = [];
  public timeByExecution : string ='';
  public totalTimeExecutionAllTime : String = '0';

  @ViewChildren(MatPaginator) public paginator = new QueryList<MatPaginator>();
  @ViewChildren(MatSort) sort = new QueryList<MatSort>();
  @ViewChild('table', { static: true, read: ElementRef })
  table?: ElementRef<HTMLDivElement>;
  @ViewChild('tableHistory', { static: true, read: ElementRef })
  tableHistory?: ElementRef<HTMLDivElement>;
  // @ViewChild('taskPaginator') taskPaginator: any;
  @ViewChild('taskPaginator', { static: true, read: ElementRef })
  taskPaginator?: ElementRef<HTMLDivElement>;
  @ViewChild('historyPaginator', { static: true, read: ElementRef })
  historyPaginator?: ElementRef<HTMLDivElement>;

  constructor(
    private serviceMachineExecution: MachineExecutionService,
    private serviceUser: UserService,
    private auth: AuthenticationService,
    private renderer: Renderer2,
    private machineAssetsService: MachineExecutionAssetsService,
    private checkListExecutionService: ChecklistExecutionService,
    private checkListItemService: ChecklistItemService,
    private snackBar: MatSnackBar
  ) { }

  ngAfterViewInit(): void {
    this.dataSourceTask.sort = this.sort.toArray()[0];
    this.dataSourceHistory.sort = this.sort.toArray()[1];

    this.dataSourceTask.paginator = this.paginator.toArray()[0];
    this.dataSourceMaps.paginator = this.paginator.toArray()[1];
    this.dataSourceMapSelected.paginator = this.paginator.toArray()[2];
    this.dataSourceHistory.paginator = this.paginator.toArray()[3];
    // this.dataTeste.sort = this.sort.toArray()[2];
    this.loadTableWidth();
  }

  ngOnInit(): void {
    this.auth.systemToken().then((_) => {
      this.loadTasksToday();
      this.loadUsers();
      this.initTypeClean();
      this.totalTimeExecution();
    });
  }
  public initTypeClean() {
    this.cleanType.set('__尘推', 'Sweep');
    this.cleanType.set('__洗地', 'Scrub');
    this.cleanType.set('__喷雾消毒', 'Desinfect');
    this.cleanType.set('Sweep', 'Sweep');
    this.cleanType.set('vaccum', 'Vaccum')
    this.cleanType.set('middle_cleaning', 'Scrub')
    this.cleanType.set('middle_cleaning', 'Scrub')
    this.cleanType.set('heavy_cleaning', 'Scrub');
    this.cleanType.set('custom_clean', 'Scrub');
    this.cleanType.set('custom_cleaning', 'Scrub');
    this.cleanType.set('suction_cleaning', 'Vaccum');



  }
  public translate(value: string) {
    return this.cleanType.get(value)
  }
  private loadTableWidth(): void {
    const width = this.table?.nativeElement.getBoundingClientRect().width;
    const widthHistory =
      this.tableHistory?.nativeElement.getBoundingClientRect().width;
    if (width && width > 1000 || widthHistory && widthHistory > 1000) {
      console.log('tamanho da tabela: ');
      console.log(width);
      console.log('tamanho da history: ');
      console.log(widthHistory);
      this.renderer.setStyle(
        this.taskPaginator?.nativeElement,
        'width',
        'auto'
      );
      this.renderer.setStyle(
        this.historyPaginator?.nativeElement,
        'width',
        widthHistory + 'auto'
      );
    } else {
      this.renderer.setStyle(
        this.taskPaginator?.nativeElement,
        'width',
        width + 'px'
      );
      this.renderer.setStyle(
        this.historyPaginator?.nativeElement,
        'width',
        widthHistory + 'px'
      );
    }
  }
  public timeExecution(exec : MachineExecution[]){
    let count : number = 0;
    exec.forEach(data =>{
      if(data.start && data.end)
        count += (data.end - data.start);
    })

    let duration = count / 1000;
    console.log(duration)
    if (duration >= 1 && duration < 60) {
      this.timeByExecution = duration.toFixed(0) + 's';
    }

    if (duration >= 60 && duration < 3600) {
      let minutes = Math.floor(duration / 60);
      let seconds = Math.floor(duration % 60);
      this.timeByExecution = `${minutes}m ${seconds}s`;
    }

    if (duration >= 3600) {
      let hours = Math.floor(duration / 3600);
      let remainingSeconds = duration % 3600;
      let minutes = Math.floor(remainingSeconds / 60);
      this.timeByExecution = `${hours}h ${minutes}m `;
    }

    return '';
  }

  public formatTimeExecution(exec: MachineExecution): string {

    if(exec.start && exec.end == undefined){
      return ''
    }
    let duration = (exec.end - exec.start) / 1000;

    if (duration >= 1 && duration < 60) {
      return duration.toFixed(0) + 's';
    }

    if (duration >= 60 && duration < 3600) {
      let minutes = Math.floor(duration / 60);
      let seconds = Math.floor(duration % 60);
      return `${minutes}m ${seconds}s`;
    }

    if (duration >= 3600) {
      let hours = Math.floor(duration / 3600);
      let remainingSeconds = duration % 3600;
      let minutes = Math.floor(remainingSeconds / 60);
      return `${hours}h ${minutes}m `;
    }

    return '';
  }

  public loadTasksToday(): void {
    this.isLoading2 = true;
    this.dateParam.setHours(0, 0, 0, 0);
    let endDay = new Date(this.dateParam);
    endDay.setHours(23, 59, 59, 999);
    let params = new HttpParams();
    params = params.append('startDate', this.dateParam.getTime());
    params = params.append('endDate', endDay.getTime());
    this.serviceMachineExecution.list(params).subscribe((executions) => {
      console.log(executions)
      this.dataSourceTask.data = executions.sort(ByDate);
      this.filterMembers();
      this.loadDataCards();
      this.loadMaps();
      this.isLoading2 = false;
      this.timeExecution(executions);
    });
  }

  public totalTimeExecution(){

    this.serviceMachineExecution.timeExecution().subscribe(seconds =>{

      console.log(seconds)

      const hours = Math.floor(seconds / 3600);
      const remainingSeconds = seconds % 3600;
      const minutes = Math.floor(remainingSeconds / 60);
      const remainingSecs = remainingSeconds % 60;

      if(hours > 999){
        this.totalTimeExecutionAllTime = hours.toString() + 'h ' + minutes.toString() +'m ' ;
        return
      }

      if(hours > 9999){
        this.totalTimeExecutionAllTime = hours.toString() + 'h ';
        return
      }
      this.totalTimeExecutionAllTime = hours.toString() + 'h '+ minutes.toString() +'m ' + remainingSecs +'s '


    })

  }

  public getAsset(execution: MachineExecution): string[] {
    this.foundAsset = []
    this.machineAssetsService.getByExecution(execution.id).subscribe((assets) => {
      assets.forEach((asset) => {
        if (asset.execution.id === execution.id) {
          this.foundAsset.push(asset.path);
        }
    });
      console.log(this.foundAsset)
      this.loadimg = false;
    });
    return this.foundAsset;
  }
  public getStatus(execution: MachineExecution): string {
    const now = Date.now();

    if (execution.start && execution.end) {
      return 'Concluído';
    }

    if (!execution.end && now - execution.start < 10800000000) {
      return 'Em andamento';
    }

    return 'Não concluída';
  }
  public getCheckListItem(exec: MachineExecution) {
    this.checkListExec = [];

    if (exec.trigger === "MANUAL") {
      this.checkListExecutionService.getCheckListByExecution(exec.id).subscribe(data => {
        this.checkListExec = data;

      })


      // this.checkListItemService.getCheckListByIdTask(exec.task.id).subscribe(data=>{
      //   this.checkListNotExecuted = data.filter(item => item.id == )
      // })
    }

  }
  comments(item: MachineExecution) {

    return item.trigger == "MANUAL" && typeof item.data == 'string';

  }
  openSnackBar(message: string, action: string) {
    this.snackBar.openFromComponent(SnackbarImageComponent, {
      data: { url: message, message: action },
    });
  }
  private loadHistory(): void {
    this.serviceMachineExecution.list().subscribe((history) => {
      this.dataSourceHistory.data = history.sort(ByDate);
      this.isLoading = false;
    });
  }

  private loadUsers(): void {
    this.serviceUser.list().subscribe((users) => {
      this.dataSourceUsers.data = users;
    });
  }

  private loadMaps(): void {
    this.dataSourceTask.data.map((item) => {
      this.dataSourceMaps.data.push(item.task.map);
    });
    this.dataSourceMaps.data.sort((a, b) => a.id - b.id);
    this.selectedMap = this.dataSourceMaps.data[0];

    this.dataSourceMaps.data = this.dataSourceMaps.data.filter(
      (value, index, self) =>
        index ===
        self.findIndex((t) => t.id === value.id && t.title === value.title)
    );

    this.obsMaps = this.dataSourceMaps.connect();
    this.filterMap();
  }

  private filterMap(): void {
    this.dataSourceMapSelected.data = this.dataSourceTask.data.filter(
      (execution) => {
        return execution.task.map.id == this.selectedMap?.id;
      }
    );

    this.obsTask = this.dataSourceMapSelected.connect();
  }

  public changeMap(map: MachineMap): void {
    if (this.selectedMap != map) {
      this.selectedMap = map;
      this.filterMap();
    }
  }

  public interval(): void {
    this.dateParam = this.date.value._d;
    this.loadTasksToday();
    setTimeout(() => {
      this.dataSourceTask.sort = this.sort.toArray()[0];
      this.dataSourceHistory.sort = this.sort.toArray()[1];
    });
  }

  public changeTab(item: any): void {
    if (item.index == 1) {
      this.loadMaps();
    } else if (item.index == 3) {
      this.loadHistory();
      this.loadTableWidth();
    }
  }

  private loadDataCards(): void {
    this.tasksManual = 0;
    this.durationLength = 0;
    this.members = [];

    this.dataSourceTask.data.map((e) => {
      if (e.trigger == this.trigger) {
        this.tasksManual += 1;
      }
      if (e.duration) {
        this.durationLength += e.duration;
      }
      if (this.members.indexOf(e.operator) == -1) {
        this.members.push(e.operator);
      }
    });
  }

  public filterMembers(): void {
    if (this.memberView != 'all') {
      if (this.memberView == 'team') {
        this.dataSourceTask.data = this.dataSourceTask.data.filter((e) => {
          return e.trigger == TriggerType.MANUAL;
        });
      } else {
        this.dataSourceTask.data = this.dataSourceTask.data.filter((e) => {
          return e.trigger == TriggerType.AUTO;
        });
      }
    }
  }
}

import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { BaseModel, BaseService } from '../auth/base.service';
import { MachineTask } from './machine-task.service';
import { Machine } from './machine.service';
import { HttpParams } from '@angular/common/http';

export enum TriggerType {
  MANUAL = "MANUAL",
  AUTO = "AUTO",
}

export interface Productivity {
  real: number;
  expected: number;
}
export interface Area {
  total: number;
  covered: number;
}

export interface MachineExecution extends BaseModel<number> {
  task: MachineTask;
  machine?: Machine;
  start: number;
  end: number;
  comments:string;
  completeness: number;
  duration: number;
  trigger: TriggerType;
  operator: string;

  productivity: Productivity;
  area: Area;

  data: any;
}

@Injectable({
  providedIn: 'root'
})
export class MachineExecutionService extends BaseService<MachineExecution, number> {
  protected path: string = `${this.config.endpoint}/hw/executions`;

  public getByMonth(month: number): Observable<MachineExecution[]> {
    console.log(`${this.path}/month${month}`)
    return this.http.get<MachineExecution[]>(`${this.path}/month?month=${month}`);
  }
  public getByFullDate(month: number, day:number, year:number): Observable<MachineExecution[]> {
    console.log(`${this.path}/day${month}${day}`)
    return this.http.get<MachineExecution[]>(`${this.path}/day?month=${month}&day=${day}&year=${year}`);
  }
  public listByMachine(machineId : number): Observable<MachineExecution> {

    let params = new HttpParams().set('machine', machineId);

    return this.http.get<MachineExecution>(`${this.path}/machine` ,{ params: params });
   }
   public getLastExecutionByTask(taskId : number): Observable<MachineExecution> {

    let params = new HttpParams().set('id', taskId);

    return this.http.get<MachineExecution>(`${this.path}/lastDataByTask` ,{ params: params });
   }


}
